import { Box, Button } from "@mui/material";
import { useNavigate } from "@remix-run/react";
import { FC } from "react";
import { FlexBox } from "~/components/flex-box";
import { Span } from "../Typography";

const FlashDeliveryButton: FC = () => {
  const navigation = useNavigate();

  const handleClick = () => {
    // console.log("Flash delivery button clicked");
    navigation("/flash-delivery");
  };

  return (
    <FlexBox sx={{ position: "relative" }}>
      <Button
        id="basic-button"
        onClick={handleClick}
        sx={{
          // color: "#000000",
          // borderRadius: 20,
          // border: "solid 2px transparent",
          // justifyContent: "space-around",
          // fontWeight: "600",
          // background:
          //   "linear-gradient(white, white), linear-gradient(to right, #423B90, #E8426B)",
          // backgroundOrigin: "border-box",
          // backgroundClip: "padding-box, border-box",
          // WebkitBackgroundClip: "text",
          // WebkitTextFillColor: "transparent",
          // textFillColor: "transparent",
          fontSize: "14px",
          border: "solid 2px transparent",
          borderRadius: "30px",
          backgroundImage:
            "linear-gradient(white, white), linear-gradient(to right, #423B90, #E8426B)",
          backgroundOrigin: "border-box",
          backgroundClip: "padding-box, border-box",
          p: "6px 10px",
        }}
      >
        <Box
          sx={{
            width: 28,
            height: 28,
            position: "relative",
            marginRight: "12px",
          }}
        >
          <img
            className=""
            src="/assets/image.png"
            alt="Brand logo"
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
        <Span
          sx={{
            gap: 1,
            lineHeight: 1,
            // fontWeight: 600,
            alignItems: "center",
            position: "relative",
            paddingBottom: "1px",
            display: "inline-flex",
            fontWeight: "600",
            // color: "#668412",
            background: "linear-gradient(to right, #F44369, #3E3B92)",
            textUnderlineOffset: "4px",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            ":after": {
              left: 0,
              bottom: 0,
              width: "0%",
              content: "''",
              height: "2px",
              transition: "0.3s",
              position: "absolute",
            },
          }}
        >
          Шуурхай хүргэлт
        </Span>
      </Button>
    </FlexBox>
  );
};

export default FlashDeliveryButton;
