import { Alert, AppBar, LinearProgress, Snackbar } from "@mui/material";
import { useLocation, useNavigation } from "@remix-run/react";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { ClientOnly } from "remix-utils/client-only";
import MessengerCustomerChat from "react-messenger-customer-chat";
import Footer from "~/components/footer/Footer";
import Header from "~/components/header/Header";
import MobileHeader from "~/components/header/MobileHeader";
import MobileNavigationBar from "~/components/mobile-navigation/MobileNavigationBar";
import Navbar from "~/components/navbar/Navbar";
import Topbar from "~/components/topbar/Topbar";
import useWindowSize from "~/hooks/useWindowSize";
import BackToTop from "../back-to-top/BackToTop";
import MuiTheme from "~/theme/MuiTheme";

/**
 *  Used in:
 *  1. market-1, matket-2, gadget-shop,
 *     fashion-shop, fashion-shop-2, fashion-shop-3, furniture-shop, grocery3, gift-shop
 *  2. product details page
 *  3. order-confirmation page
 *  4. product-search page
 *  5. shops and shops-details page
 *  6. checkoutNavLayout and CustomerDashboadLayout component
 */

// ===================================================
type ShopLayoutProps = {
  children?: ReactNode;
  showTopbar?: boolean;
  showNavbar?: boolean;
  topbarBgColor: string;
};
// ===================================================

const ShopLayout: FC<ShopLayoutProps> = ({
  children,
  showTopbar = true,
  topbarBgColor,
  showNavbar = true,
}) => {
  const { search } = useLocation();
  const [open, setOpen] = React.useState(search.includes("?message=ib"));
  const navigation = useNavigation();
  const showLoading = navigation.state !== "idle";
  const width = useWindowSize();
  const [mobileWidth, setMobileWidth] = useState(false);
  useEffect(() => {
    window.innerWidth <= 1024 ? setMobileWidth(true) : setMobileWidth(false);
  }, [width]);

  useEffect(() => {
    if (search.includes("?message=ib")) {
      console.log(`------- balance`);
    }
  }, [search]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <MuiTheme>
      <Snackbar
        open={open}
        autoHideDuration={10000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Барааны үлдэгдэл хүрэлцэхгүй байна!
        </Alert>
      </Snackbar>

      {/* TOPBAR */}
      <Topbar bgColor={topbarBgColor} showLoading={showLoading} />

      {/* HEADER */}

      {mobileWidth ? (
        <AppBar position="sticky">
          <>
            {showLoading && (
              <LinearProgress color="primary" sx={{ height: 4 }} />
            )}
            <MobileHeader />
          </>
        </AppBar>
      ) : (
        <AppBar position="sticky">
          <>
            {showLoading && (
              <LinearProgress color="primary" sx={{ height: 4 }} />
            )}
            <Header />
          </>
        </AppBar>
      )}

      <div className="section-after-sticky">
        {/* NAVIGATION BAR */}
        {showNavbar && <Navbar elevation={0} border={1} />}

        {/* BODY CONTENT */}
        {children}
      </div>

      <ClientOnly fallback={<></>}>
        {() => (
          <div className="fbbmain">
            <MessengerCustomerChat
              pageId="1412443348975428"
              appId="242254631589579"
              style={{ marginBottom: "50px" }}
            />
          </div>
        )}
      </ClientOnly>

      <MobileNavigationBar />

      <ClientOnly fallback={<></>}>{() => <BackToTop />}</ClientOnly>
      <Footer key="main-footer" />
    </MuiTheme>
  );
};

export default ShopLayout;
