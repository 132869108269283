import { Add, Close, Remove } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link, useNavigation } from "@remix-run/react";
import { useEffect } from "react";
import BazaarButton from "~/components/BazaarButton";
import BazaarIconButton from "~/components/BazaarIconButton";
import BazaarImage from "~/components/BazaarImage";
import { H5, Tiny } from "~/components/Typography";
import { FlexBox } from "~/components/flex-box";
import { CartProduct } from "~/models";
import RemoveFromCartForm from "../cart/RemoveFromCartForm";
import UpdateQtyCartForm from "../cart/UpdateQtyCartForm";
import { formatNumber } from "~/utils/utils";
import { AUCTION_FEE_PRODUCT_ID } from "~/utils/constants";

type MiniCartProductProps = {
  product: CartProduct;
  onClose: any;
};
// =========================================================

const MiniCartProductCard: React.FC<MiniCartProductProps> = ({
  product,
  onClose,
}) => {
  const handleOnClick = () => {
    onClose(false);
  };
  const { palette } = useTheme();
  let navigation = useNavigation();

  let isSubmitting = navigation.state !== "idle";
  let isAllowedToSell: boolean = product.salableQty > product.quantity;

  return (
    <FlexBox
      py={2}
      px={2.5}
      key={product.id}
      alignItems="center"
      borderBottom={`1px solid ${palette.divider}`}
    >
      <FlexBox alignItems="center" flexDirection="column" width={"60px"} pr={1}>
        {product.sku !== AUCTION_FEE_PRODUCT_ID && (
          <UpdateQtyCartForm
            itemId={product.id}
            quantity={product.quantity + 1}
          >
            <BazaarButton
              color="primary"
              variant="outlined"
              type="submit"
              sx={{ height: "32px", width: "32px", borderRadius: "4px" }}
              disabled={isSubmitting || !isAllowedToSell}
            >
              <Add fontSize="small" />
            </BazaarButton>
          </UpdateQtyCartForm>
        )}

        <Box fontWeight={600} fontSize="15px" my="3px">
          {formatNumber(product.quantity)}
        </Box>

        <UpdateQtyCartForm itemId={product.id} quantity={product.quantity - 1}>
          <BazaarButton
            color="primary"
            variant="outlined"
            type="submit"
            sx={{ height: "32px", width: "32px", borderRadius: "4px" }}
            disabled={isSubmitting || product.quantity === 1}
          >
            <Remove fontSize="small" />
          </BazaarButton>
        </UpdateQtyCartForm>
      </FlexBox>

      <Link to={product.url} onClick={handleOnClick}>
        <Box sx={{ p: 2 }}>
          <BazaarImage
            alt={product.title}
            src={product.image}
            width={76}
            height={76}
          />
        </Box>
      </Link>

      <Box flex="1 1 0">
        <Link to={product.url} onClick={handleOnClick}>
          <H5 className="title" fontSize="14px">
            {product.title}
          </H5>
        </Link>

        <Tiny color="grey.600" fontSize={"13px"}>
          {product.sku === "C100225" || product.sku === "1202453"
            ? formatNumber(product.quantity) + "₮"
            : product.formattedPrice + "x" + formatNumber(product.quantity)}
          <br />
        </Tiny>

        {product.salableQty <= 0 && (
          <Box>
            <Tiny color="red.500" fontSize={"10px"}>
              Уг бүтээгдэхүүн дууссан байна!
            </Tiny>
          </Box>
        )}
      </Box>

      <RemoveFromCartForm itemId={product.id}>
        <BazaarIconButton
          ml={2.5}
          size="small"
          type="submit"
          disabled={
            navigation.state === "submitting" || navigation.state === "loading"
          }
        >
          <Close fontSize="small" />
        </BazaarIconButton>
      </RemoveFromCartForm>
    </FlexBox>
  );
};
export default MiniCartProductCard;
